import type { PromoDataProps } from "modules/promos/types"
import { push } from "common/components/GoogleTagManager"

export enum PromoTrackingEvent {
  Impression = "Impression",
  Dismiss = "Dismiss",
  Engagement = "Engagement",
}

export const trackPromoEvent = (promo: PromoDataProps, trackingEvent: PromoTrackingEvent) => {
  const label = promo.campaign?.name

  if (label) {
    const { type: promo_type, promoId, category } = promo
    const action = `${trackingEvent} - Promo #${promoId}`

    // dataLayer convention: event name will be snake cased. We'll have three different
    // events:
    // => promo_impression
    // => promo_dismiss
    // => promo_engagement
    push({
      event: `promo_${trackingEvent.toLowerCase()}`,
      promo_id: promoId,
      promo_type: promo_type,
      promo_campaign_name: label,
      promo_category: category,
    })
  }
}
