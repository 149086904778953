import { PropsWithChildren, ReactNode } from "react"
import clsx from "classnames"
import { useDropdown } from "common/hooks/interaction/useDropdown"

interface DropdownProps {
  overlay: ReactNode
  placement?: "left" | "right"
  wideOverlay?: boolean
}

export const Dropdown = ({
  children,
  overlay,
  placement = "left",
  wideOverlay,
}: PropsWithChildren<DropdownProps>) => {
  const { ref, isActive, onClick } = useDropdown()
  return (
    <div ref={ref} className="relative inline-block">
      <div onClickCapture={onClick} className="inline-flex h-full">
        {children}
      </div>
      <div
        className={clsx(
          "absolute flex w-36 min-w-max flex-col border border-t-0 border-gray-100 bg-white",
          {
            hidden: !isActive,
            "right-0": placement === "right",
            "w-36": !wideOverlay,
            "w-48": wideOverlay,
          },
        )}
        role="menu"
        aria-orientation="vertical"
      >
        {overlay}
      </div>
    </div>
  )
}
