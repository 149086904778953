import type { PropsWithChildren } from "react"
import { linkClassNames } from "common/components/Footer"

export const Link = ({
  href,
  title,
  children,
}: PropsWithChildren<{ href: string; title?: string }>) => (
  <a title={title} href={href} className={linkClassNames}>
    {children}
  </a>
)
