import type { PropsWithChildren } from "react"
import type { IconProps } from "../icons.types"

export const MastodonIcon = ({ className }: PropsWithChildren<IconProps>) => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    role="img"
  >
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        d="M16.296 17.244c2.506-.3 4.688-1.843 4.963-3.254.432-2.223.396-5.424.396-5.424 0-4.338-2.843-5.61-2.843-5.61-1.433-.658-3.894-.935-6.45-.956h-.064c-2.557.02-5.016.298-6.45.956 0 0-2.842 1.272-2.842 5.61l-.003.828c-.004.8-.008 1.687.015 2.613.103 4.243.778 8.425 4.7 9.463 1.81.48 3.363.58 4.613.51 2.268-.125 3.542-.809 3.542-.809l-.075-1.646s-1.621.512-3.441.45c-1.804-.063-3.708-.195-4-2.41a4.54 4.54 0 0 1-.04-.62s1.77.432 4.014.535c1.372.063 2.659-.08 3.965-.236Zm2.006-3.088V8.903c0-1.074-.273-1.927-.822-2.558-.567-.631-1.308-.955-2.229-.955-1.065 0-1.871.41-2.405 1.228l-.518.87-.519-.87C11.276 5.8 10.47 5.39 9.405 5.39c-.921 0-1.663.324-2.229.955-.549.631-.822 1.484-.822 2.558v5.253h2.081V9.057c0-1.075.452-1.62 1.357-1.62 1 0 1.501.647 1.501 1.927v2.79h2.07v-2.79c0-1.28.5-1.927 1.5-1.927.905 0 1.358.545 1.358 1.62v5.1h2.08Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M3 2h18.656v20H3z" />
      </clipPath>
    </defs>
  </svg>
)
