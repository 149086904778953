import { Bugsnag } from "./bugsnag"

const BASE_URL = "https://theconversation.com"

interface UTMParams {
  campaign?: string
  content: string
  medium: string
}

/**
 * Generates promos href with UTM params, merging any query string in given `url`, except for UTM params.
 * UTM params from the given `url` will NOT be replaced.
 * @param url Should be valid URL, absolute or relative. Examples: https://donate.theconversation.com/au?utm_campaign=AU+Donations, /au/newsletters/newsletter-1
 * @param params UTM params to merge into the URL
 */
export function setUTMParams(url: string, params: UTMParams): string {
  try {
    const urlResult = new URL(url, BASE_URL)
    const { campaign, content, medium } = params

    if (!urlResult.searchParams.has("utm_campaign") && campaign) {
      urlResult.searchParams.set("utm_campaign", campaign)
    }

    if (!urlResult.searchParams.has("utm_source")) {
      urlResult.searchParams.set("utm_source", "theconversation.com")
    }

    if (!urlResult.searchParams.has("utm_medium")) {
      urlResult.searchParams.set("utm_medium", medium)
    }

    if (!urlResult.searchParams.has("utm_content")) {
      urlResult.searchParams.set("utm_content", content)
    }

    return urlResult.toString()
  } catch (e) {
    Bugsnag.notify(e as Error)
    return url
  }
}
