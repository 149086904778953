import { DonationDialog } from "modules/promos/components/DonationDialog"
import { StickyPopup } from "modules/promos/components/StickyPopup"
import { PlacementEngineEvent } from "modules/promos/contexts/PromosContext"
import { PromosTheme, PromoThemeName } from "modules/promos/contexts/PromosTheme"
import { PromoDataProps, PromoTypes } from "modules/promos/types"
import { EffectFn1 } from "common/lib/types"

export interface CreativeProps {
  promo: PromoDataProps
  events: {
    onClick: PlacementEngineEvent
    onClose: PlacementEngineEvent
  }
  // ref type from `react-intersection-observer`
  creativeRef: EffectFn1<Element | null>
}

/**
 * Factory like component, that renders the promo creative based on the given promo data type.
 */
export const Creative = ({ promo, events, creativeRef }: CreativeProps) => {
  const theme = promo.data?.theme || PromoThemeName.Default
  const creativeProps = { ...promo.data, ...events, creativeRef }

  switch (promo.type) {
    case PromoTypes.DonationDialog:
      return <DonationDialog {...creativeProps} />
    case PromoTypes.StickyPopup:
      return (
        <PromosTheme name={theme}>
          <StickyPopup {...creativeProps} />
        </PromosTheme>
      )
    default:
      return null
  }
}
