import { MouseEventHandler, RefObject, useState } from "react"
import { XMarkIcon } from "@heroicons/react/20/solid"
import { usePromosTheme } from "modules/promos/hooks/usePromosTheme"
import clsx from "classnames"
import { useTranslations } from "modules/i18n/hooks/useTranslations"

interface DonationDialogProps {
  message: string
  buttonText: string
  onClose: Function
  onClick: MouseEventHandler<HTMLButtonElement>
  creativeRef: RefObject<HTMLDivElement>
}

export const StickyPopup = ({
  message,
  buttonText,
  onClose,
  onClick,
  creativeRef,
}: DonationDialogProps) => {
  const [open, setOpen] = useState(true)
  const { backgroundColor } = usePromosTheme()
  const t = useTranslations("promos")

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  if (!open) {
    return null
  }

  return (
    <div
      className={clsx(
        "fixed inset-x-2 bottom-2 mx-auto max-w-md rounded text-center text-white shadow-lg md:bottom-4",
        backgroundColor,
      )}
      ref={creativeRef}
      data-testid="sticky-popup"
    >
      <p className="px-12 pb-6 pt-12 font-sans-heading text-lg font-semibold leading-snug">
        {message}
      </p>

      <div className="pb-12">
        <button
          className="inline-block rounded bg-gray-100 px-5 py-3 font-semibold text-black hover:bg-gray-200"
          onClick={onClick}
        >
          {buttonText}
        </button>
      </div>

      <button
        type="button"
        className="absolute right-3 top-3"
        onClick={handleClose}
        aria-label={t("button.close")}
      >
        <XMarkIcon className="size-5" />
      </button>
    </div>
  )
}
