import { useRef, useState, useCallback } from "react"
import { useOutsideClick } from "./useOutsideClick"

/**
 * This hook manages the state of a dropdown.
 *
 * It returns a `ref` for the dropdown, an `onClick` callback, and an `isActive` value which is
 * toggled whenever the dropdown's active state changes.
 * @deprecated `useDropdown` usage is deprecated. Use `common/components/Dropdown` instead.
 *  Or if you need more fine-grained customisation, check `common/components/Overlay`.
 */
export const useDropdown = () => {
  const ref = useRef(null)
  const [isActive, setActive] = useState(false)
  const onClick = useCallback(() => setActive(!isActive), [isActive])
  const onOutsideClick = useCallback(() => setActive(false), [])

  useOutsideClick(ref, onOutsideClick)

  return { ref, isActive, onClick }
}
