import React, { PropsWithChildren, useCallback } from "react"
import { SearchForm } from "../SearchForm"
import clsx from "classnames"
import { usePageProps } from "common/hooks/data/usePageProps"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import type { AppProps } from "common/lib/getAppServerSideProps"
import { pageUrl } from "common/lib/router"
import { useRegionConfig } from "../../../../modules/i18n/hooks/useRegionConfig"

interface MobileMenuProps {
  isActive: boolean
}

export const MobileMenu = ({ isActive }: PropsWithChildren<MobileMenuProps>) => {
  const { navItems, activeRegions, pageSlug, regionCode } = usePageProps<AppProps>()
  const {
    section_nav: { custom_nav_link: customNavLink },
  } = useRegionConfig()
  const currentPathname = pageUrl.view({ regionCode, pageSlug, prefix: false })
  const isCustomNavLinkActive = currentPathname === customNavLink.link
  const t = useTranslations("layout.header")

  const onRegionChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    window.location.assign(`/${event.target.value}`)
  }, [])

  return (
    <section
      className={clsx(
        "absolute w-full overflow-hidden overflow-y-auto bg-white pt-11 text-gray-800 transition-all duration-500",
        {
          "h-screen": isActive,
          "h-0": !isActive,
        },
      )}
      role="region"
      data-testid="mobileMenu"
    >
      <div className="m-4">
        <SearchForm />
      </div>

      <div
        className="my-3 mb-8 flex flex-col font-sans-heading text-xl font-bold"
        role="menu"
        aria-orientation="vertical"
      >
        {navItems.map(({ path, title }) => {
          const isCurrentPage = currentPathname === path
          return (
            <a
              key={path}
              href={path}
              className={clsx("my-1.5 border-l-8 py-0.5 pl-2", {
                "border-gray-700": isCurrentPage,
                "border-white": !isCurrentPage,
              })}
              role="menuitem"
            >
              {title}
            </a>
          )
        })}
        {customNavLink.enabled ? (
          <a
            href={customNavLink.link}
            className={clsx("my-1.5 border-l-8 py-0.5 pl-2", {
              "border-gray-700": isCustomNavLinkActive,
              "border-white": !isCustomNavLinkActive,
            })}
            role="menuitem"
          >
            {customNavLink.title}
          </a>
        ) : null}
      </div>

      <div className="m-4">
        <label htmlFor="mobile-region-dropdown" className="mb-1 block text-sm font-bold">
          {t("edition")}:
        </label>
        <div className="w-full rounded-sm border border-gray-400 p-2">
          <select
            value={regionCode}
            onChange={onRegionChange}
            className="w-full bg-white text-sm outline-none"
          >
            {activeRegions.map(({ code, nameInRegionLanguage }) => (
              <option value={code} key={code}>
                {nameInRegionLanguage}
              </option>
            ))}
          </select>
        </div>
      </div>
    </section>
  )
}
