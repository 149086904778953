import { useEffect, useState, useCallback, startTransition } from "react"

export const useHasBeenInViewport = (rootMargin = 0) => {
  const [hasBeenInViewport, setHasBeenInViewport] = useState(false)
  const [subject, setSubject] = useState<Element | null>(null)
  const setRef = useCallback((element: Element | null) => {
    if (element) {
      // Marking state through `startTransition` makes it non-urgent, without blocking more urgent UI updates.
      // It resolves also "This Suspense boundary received an update before it finished hydrating" error,
      // which seems to be triggered by state updates originated from `IntersectionObserver`.
      // More: https://github.com/conversation/tc/pull/13700
      startTransition(() => {
        setSubject(element)
      })
    }
  }, [])

  useEffect(() => {
    // The functionality here relies on the `IntersectionObserver` API to tell if the given element
    // has been in the viewport. This API is not available in all browsers. If it isn't, we need to
    // fail safe by assuming that the element has been in the viewport.
    // https://caniuse.com/intersectionobserver
    if (typeof IntersectionObserver === "undefined") {
      setHasBeenInViewport(true)
      return
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasBeenInViewport) {
          // Marking state through `startTransition` makes it nonurgent, without blocking more urgent UI updates.
          // It resolves also "This Suspense boundary received an update before it finished hydrating" error,
          // which seems to be triggered by state updates originated from `IntersectionObserver`.
          // More: https://github.com/conversation/tc/pull/13700
          startTransition(() => {
            setHasBeenInViewport(entry.isIntersecting)
          })
        }
      },
      {
        rootMargin: `${rootMargin}px`,
      },
    )
    if (subject) {
      observer.observe(subject)
    }
    return () => {
      observer.disconnect()
    }
  }, [subject, rootMargin, hasBeenInViewport])

  return { setRef, hasBeenInViewport }
}
