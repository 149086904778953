import type { PropsWithChildren } from "react"
import { IconProps } from "../icons.types"

export const LinkedInIcon = ({ className }: PropsWithChildren<IconProps>) => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    role="img"
  >
    <path d="M6.36 9c.354 0 .64.293.64.655v11.69a.647.647 0 0 1-.64.655H3.64a.647.647 0 0 1-.64-.655V9.655C3 9.293 3.286 9 3.64 9h2.72Zm10.148 0c4.262 0 4.48 3.925 4.491 6.404l.001.29v5.713a.581.581 0 0 1-.568.593h-2.821a.581.581 0 0 1-.568-.593v-5.402c0-.806.226-3.531-2.016-3.531-1.739 0-2.091 1.865-2.162 2.702v6.23a.581.581 0 0 1-.568.594h-2.73A.581.581 0 0 1 9 21.407V9.787c0-.328.254-.594.568-.594h2.729c.313 0 .568.266.568.594v1.004C13.509 9.781 14.468 9 16.508 9ZM5 8a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
  </svg>
)
