import clsx from "classnames"
import { Dropdown } from "../Dropdown"
import { Button } from "../Button"
import { HomeIcon, PencilSquareIcon, ChevronDownIcon } from "@heroicons/react/20/solid"
import { AccountMenu } from "../AccountMenu"
import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { useRegionConfig } from "modules/i18n/hooks/useRegionConfig"
import { useViewer } from "common/hooks/data/useViewer"
import { usePageProps } from "common/hooks/data/usePageProps"
import type { AppProps } from "common/lib/getAppServerSideProps"

interface DesktopTopbarProps {
  wide?: boolean
}

export const DesktopTopbar = ({ wide }: DesktopTopbarProps) => {
  const regionCode = useRegionCode()
  const { editUrl, activeRegions } = usePageProps<AppProps>()
  const t = useTranslations("layout.header")
  const { stay_informed, donations } = useRegionConfig()
  const { viewer, isAuthor, isEditor } = useViewer()
  const user = viewer?.user
  const selectedRegion = activeRegions.find(({ code }) => code == regionCode)
  const remainingRegions = activeRegions.filter(({ code }) => code != regionCode)

  const regionDropdownOverlay = (
    <>
      {remainingRegions.map(({ code, nameInRegionLanguage }) => (
        <Button href={`/${code}`} key={code} role="menuitem">
          {nameInRegionLanguage}
        </Button>
      ))}
    </>
  )
  return (
    <div className="relative z-10 hidden bg-white lg:block" data-testid="desktopTopbar">
      <section
        className={clsx("mx-auto flex justify-between", {
          "tc-container container": !wide,
        })}
      >
        <div className="flex">
          <Button href={`/${regionCode}`} type="icon" withDivider ariaLabel={t("go_to_homepage")}>
            <HomeIcon name="Home" role="button" className="w-4" />
          </Button>

          <div className="flex items-center py-3.5 pl-2.5 pr-1 text-xs text-gray-700">
            {t("edition")}:
          </div>

          {selectedRegion && (
            <Dropdown overlay={regionDropdownOverlay}>
              <Button withDivider={!donations.enabled}>
                <div className="mr-1 text-red-500">{selectedRegion.nameInRegionLanguage}</div>
                <ChevronDownIcon name="Arrow pointing down" className="w-4 text-gray-700" />
              </Button>
            </Dropdown>
          )}

          {donations.enabled && (
            <Button
              type="alert"
              href={`https://donate.theconversation.com/${regionCode}?utm_source=theconversation.com&utm_medium=website&utm_campaign=topbar`}
            >
              {t("donate")}
            </Button>
          )}

          <Button href={stay_informed.topbar.url}>{t("get_newsletter")}</Button>
        </div>

        <div className="flex">
          {isEditor && editUrl && (
            <Button href={editUrl} withDivider>
              <PencilSquareIcon className="w-5 fill-gray-600 pr-1" />
              {t("user_navbar.edit_page")}
            </Button>
          )}
          {isAuthor && user?.id && (
            <Button href={`/profiles/${user.id}/dashboard`} withDivider>
              {t("user_navbar.dashboard")}
            </Button>
          )}
          {isEditor && user?.id && (
            <Button href={`/editorial/dashboards/${user.id}`} withDivider>
              {t("user_navbar.dashboard")}
            </Button>
          )}
          {!user && (
            <>
              <Button href="/become-an-author" withDivider>
                {t("user_navbar.become_an_author")}
              </Button>
              <Button href={`/sign_up?return_to=/${regionCode}`} withDivider>
                {t("user_navbar.signup_reader")}
              </Button>
            </>
          )}
          <AccountMenu />
        </div>
      </section>
    </div>
  )
}
