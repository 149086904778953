import type { PropsWithChildren } from "react"
import Link from "next/link"
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
  YouTubeIcon,
  MastodonIcon,
  TikTokIcon,
  WhatsAppIcon,
} from "common/components/icons/social"

interface SocialMediaLinkProps {
  icon: string
  href: string
  title?: string
}

interface SocialMediaIconProps {
  icon: string
}

const SocialMediaIcon = ({ icon }: PropsWithChildren<SocialMediaIconProps>) => {
  switch (icon) {
    case "facebook":
      return (
        <div className="group flex size-14 rounded-full bg-white hover:bg-facebook lg:size-11">
          <FacebookIcon className="m-auto size-5 fill-facebook group-hover:fill-white lg:size-4" />
        </div>
      )
    case "twitter":
      return (
        <div className="group flex size-14 rounded-full bg-white hover:bg-twitter lg:size-11">
          <TwitterIcon className="m-auto size-5 fill-twitter group-hover:fill-white lg:size-4" />
        </div>
      )
    case "instagram":
      return (
        <div className="group flex size-14 rounded-full bg-white hover:bg-instagram lg:size-11">
          <InstagramIcon className="m-auto size-5 fill-instagram group-hover:fill-white lg:size-4" />
        </div>
      )
    case "linkedin":
      return (
        <div className="group flex size-14 rounded-full bg-white hover:bg-linkedin lg:size-11">
          <LinkedInIcon className="m-auto size-5 fill-linkedin group-hover:fill-white lg:size-4" />
        </div>
      )
    case "youtube":
      return (
        <div className="group flex size-14 rounded-full bg-white hover:bg-youtube lg:size-11">
          <YouTubeIcon className="m-auto size-5 fill-youtube group-hover:fill-white lg:size-4" />
        </div>
      )
    case "mastodon":
      return (
        <div className="group flex size-14 rounded-full bg-white hover:bg-mastodon lg:size-11">
          <MastodonIcon className="m-auto size-5 fill-mastodon group-hover:fill-white lg:size-4" />
        </div>
      )
    case "tiktok":
      return (
        <div className="group flex size-14 rounded-full bg-white hover:bg-black lg:size-11">
          <TikTokIcon className="m-auto size-5 fill-black group-hover:fill-white lg:size-4" />
        </div>
      )
    case "whatsapp":
      return (
        <div className="group flex size-14 rounded-full bg-white hover:bg-whatsapp lg:size-11">
          <WhatsAppIcon className="m-auto size-5 fill-whatsapp group-hover:fill-white lg:size-4" />
        </div>
      )
    default:
      return null
  }
}

export const SocialMediaLink = ({ icon, title, href }: PropsWithChildren<SocialMediaLinkProps>) => {
  return (
    <Link href={href} title={title} rel="me">
      <SocialMediaIcon icon={icon} />
    </Link>
  )
}
