import { EffectFn1 } from "common/lib/types"
import { useEffect, RefObject } from "react"

/**
 * This hook detects click events that occur *outside* of a given reference node.
 *
 * The given callback function is called when a click event occurs on a node that is not one of the
 * reference node's descendants (i.e. the node itself, or one of its descendant children).
 *
 * @param ref The reference node.
 * @param callback The callback function called when a click event occurs.
 */
export const useOutsideClick = (ref: RefObject<Node>, callback: EffectFn1<Event>) => {
  useEffect(() => {
    const listener = (event: Event) => {
      // Only call callback if clicking *outside* ref's descendants
      if (!ref.current?.contains(event.target as Node)) {
        callback(event)
      }
    }
    document.addEventListener("click", listener, true)
    return () => {
      document.removeEventListener("click", listener, true)
    }
  }, [ref, callback])
}
