import { FC, useEffect } from "react"
import { useDropdown } from "common/hooks/interaction/useDropdown"
import { MobileMenu } from "../MobileMenu"
import { AccountMenu } from "../AccountMenu"
import { Button } from "../Button"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { TcLogo } from "common/components/TcLogo"

export const MobileTopbar = () => {
  const { ref, isActive, onClick } = useDropdown()
  const t = useTranslations("layout.header")

  useEffect(() => {
    document.body.classList.toggle("overflow-hidden", isActive)
  }, [isActive])

  return (
    <div className="relative z-10 bg-white lg:hidden" ref={ref} data-testid="mobileTopbar">
      <MobileMenu isActive={isActive} />
      <section className="relative flex justify-between border-b bg-white">
        <Button type="bold" onClick={onClick}>
          {isActive ? t("close") : t("menu")}
        </Button>

        <AccountMenu />
      </section>
      <div className="absolute inset-y-0 left-1/4 mx-auto flex w-1/2 items-center justify-center">
        <TcLogo width={200} height={20} />
      </div>
    </div>
  )
}
