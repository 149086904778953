import type { PropsWithChildren } from "react"
import { useRegionConfig } from "modules/i18n/hooks/useRegionConfig"
import { Link } from "common/components/Footer/Link"
import { SocialMediaLink } from "common/components/Footer/SocialMediaLink"
import {
  ConsentPreferencesLink,
  isConsentPreferencesLink,
} from "common/components/Footer/ConsentPreferencesLink"
import { FooterLink } from "modules/i18n/utils/loadRegionConfiguration"

interface FooterProps {
  year: number
}

export const Footer = ({ year }: PropsWithChildren<FooterProps>) => {
  const {
    footer: {
      first_column: firstColumn,
      second_column: secondColumn,
      social_media: socialMedia,
      copyright_first_column: copyrightFirstColumn,
      copyright_notice_html: copyrightNoticeHtml,
    },
  } = useRegionConfig()

  const mapFooterLinks = (links: FooterLink[]) =>
    links.map(({ url, title, label }) => {
      if (isConsentPreferencesLink(url)) {
        return (
          <ConsentPreferencesLink key={url} title={title}>
            {label}
          </ConsentPreferencesLink>
        )
      }

      return (
        <Link key={url} href={url} title={title}>
          {label}
        </Link>
      )
    })

  const firstColumnLinks = mapFooterLinks(firstColumn)
  const secondColumnLinks = mapFooterLinks(secondColumn)
  const copyrightColumnLinks = mapFooterLinks(copyrightFirstColumn)

  const socialMediaLinks = socialMedia.map(({ url, title, icon }) => (
    <SocialMediaLink key={url} title={title} href={url} icon={icon} />
  ))

  return (
    <footer
      className="mt-auto bg-gray-900 text-center text-white md:text-left lg:text-left"
      data-testid="footer"
    >
      <div className="tc-container container mx-auto flex flex-col flex-wrap py-5 md:flex-row">
        <div className="order-2 flex grow flex-col md:order-1">{firstColumnLinks}</div>

        <div className="order-1 flex grow flex-col md:order-2">{secondColumnLinks}</div>

        <div className="order-last flex basis-full flex-wrap justify-center gap-3 py-5 lg:basis-auto lg:gap-2.5 lg:py-0">
          {socialMediaLinks}
        </div>
      </div>

      <div className="bg-black">
        <div className="tc-container container mx-auto flex flex-col gap-4 py-4 text-2xs md:flex-row 2xl:text-xs">
          {copyrightColumnLinks}

          <div className="mx-auto lg:ml-auto lg:mr-0">
            <span
              dangerouslySetInnerHTML={{
                __html: copyrightNoticeHtml.replace("%{year}", year.toString()),
              }}
            />
          </div>
        </div>
      </div>
    </footer>
  )
}
