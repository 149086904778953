import type { PropsWithChildren } from "react"
import type { IconProps } from "../icons.types"

export const FacebookIcon = ({ className }: PropsWithChildren<IconProps>) => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    className={className}
  >
    <path d="M17 9.626h-3.77V7.062C13.23 5.982 13.61 5 15 5h2V1.589s-1.382-.293-2.878-.293C11 1.296 9.03 3.122 9.03 6.548v3.078H5.488v3.952H9v9.552c.696.11 1.368.166 2.095.166.726 0 1.44-.057 2.136-.166v-9.552h3.185L17 9.626Z" />
  </svg>
)
