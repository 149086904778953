import { placementEngine } from "@theconversation/promos-client"
import { createContext, PropsWithChildren, useEffect, useState } from "react"
import { usePromosData } from "modules/promos/hooks/usePromosData"
import throttle from "lodash/throttle"
import type { PromoDataProps } from "modules/promos/types"
import { EffectFn1 } from "common/lib/types"
import { useConsent } from "common/hooks/data/useConsent"
import {
  THE_CONVERSATION_PROMOS_PURPOSE,
  THE_CONVERSATION_SDK_ID,
} from "common/components/Didomi/Didomi"
import { getLocalStorage } from "common/lib/getLocalStorage"
import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import { useViewer } from "common/hooks/data/useViewer"

// A `PlacementEngineEvent` defines events coming from the placement engine. These events require the promo as
// an argument, so the placement engine can update the user local storage based on user interaction (engagement, block, etc.)
export type PlacementEngineEvent = EffectFn1<PromoDataProps>

// The placement engine observable is the resulting object after it processes the promos pipeline. It is accessible
// by subscribing the the engine's signal.
type PlacementEngineObservable = {
  promos: PromoDataProps[]
  onClose: PlacementEngineEvent
  onClick: PlacementEngineEvent
  onView: PlacementEngineEvent
  onRefresh: PlacementEngineEvent
}

export type PromosPlacementEngineProps = {
  promos: PromoDataProps[]
  events: {
    onClose: PlacementEngineEvent
    onClick: PlacementEngineEvent
    onView: PlacementEngineEvent
  }
}

export const PromosPlacementEngineContext = createContext<PromosPlacementEngineProps>({
  promos: [],
  events: {
    onClose: () => {},
    onClick: () => {},
    onView: () => {},
  },
})

export const PromosContext = ({ children }: PropsWithChildren) => {
  const candidatePromos = usePromosData()
  const consent = useConsent(THE_CONVERSATION_SDK_ID, THE_CONVERSATION_PROMOS_PURPOSE)
  const region = useRegionCode()
  const { viewer } = useViewer()
  const [promos, setPromos] = useState<PromoDataProps[]>([])
  const [onClose, setOnClose] = useState(() => () => {})
  const [onClick, setOnClick] = useState(() => () => {})
  const [onView, setOnView] = useState(() => () => {})
  const [onRefresh, setOnRefresh] = useState(() => () => {})

  // Set promos engine events on the context state
  useEffect(() => {
    // Do nothing if we don't have a viewer.
    if (!viewer) {
      return
    }

    // Do nothing if we don't have any promos to render.
    if (candidatePromos.length === 0) {
      return
    }

    const context = { consent, region, user: { donations: viewer.user?.donations } }
    const signal = placementEngine(getLocalStorage(), candidatePromos, context)
    signal.subscribe((observable: PlacementEngineObservable) => {
      setPromos(observable.promos)
      setOnClose(() => observable.onClose)
      setOnClick(() => observable.onClick)
      setOnView(() => observable.onView)
      setOnRefresh(() => observable.onRefresh)
    })

    return () => {
      signal.unsubscribe()
    }
  }, [candidatePromos, consent, region, viewer])

  // Configure resize and scroll event listeners
  useEffect(() => {
    // Throttle refresh events to at most one per second.
    const REFRESH_RATE = 1000
    const handleRefresh = throttle(() => onRefresh(), REFRESH_RATE)

    document.addEventListener("resize", handleRefresh)
    document.addEventListener("scroll", handleRefresh, { passive: true })

    return () => {
      document.removeEventListener("scroll", handleRefresh)
      document.removeEventListener("resize", handleRefresh)
    }
  }, [candidatePromos, onRefresh])

  return (
    <PromosPlacementEngineContext.Provider value={{ promos, events: { onClose, onClick, onView } }}>
      {children}
    </PromosPlacementEngineContext.Provider>
  )
}
