export enum Locale {
  En = "en",
  EnAu = "en-AU",
  EnCa = "en-CA",
  EnEurope = "en-EUROPE",
  EnGb = "en-GB",
  EnGlobal = "en-GLOBAL",
  EnNz = "en-NZ",
  EnUs = "en-US",
  EnZa = "en-ZA",
  Es = "es",
  EsEs = "es-ES",
  Fr = "fr",
  FrFr = "fr-FR",
  FrCa = "fr-CA",
  Id = "id",
  IdId = "id-ID",
  Pt = "pt",
  PtBr = "pt-BR",
}

export const DEFAULT_LOCALE: Locale = Locale.En

export const LOCALE_SEPARATOR = "-"

export const LOCALES = Object.values(Locale)
