import { useCallback, useEffect, useState } from "react"
import { consentStatus } from "common/components/Didomi/Didomi"
import { useRegionConfig } from "modules/i18n/hooks/useRegionConfig"

/**
 * Hook to get the consent status of the user.
 * This hook returns the consent status given a Didomi vendor id and its purpose.
 * The consent status is updated when the `didomi:consent-changed` event is triggered.
 */
export const useConsent = (vendorId: string, purpose: string): boolean | undefined => {
  const regionConfiguration = useRegionConfig()
  const [consent, setConsent] = useState<boolean | undefined>(undefined)

  const handler = useCallback(async () => {
    const consent = await consentStatus({ regionConfiguration, vendorId, purpose })

    setConsent(consent)
  }, [regionConfiguration, vendorId, purpose])

  useEffect(() => {
    void handler()

    window.addEventListener("didomi:consent.changed", handler)

    return () => {
      window.removeEventListener("didomi:consent.changed", handler)
    }
  }, [handler])

  return consent
}
