import { usePromosPlacementEngine } from "modules/promos/hooks/usePromosPlacementEngine"
import { Renderer } from "modules/promos/components/Slot/Renderer"
import type { PromoDataProps } from "modules/promos/types"

export interface SlotProps {
  name: string
}

export const Slot = ({ name }: SlotProps) => {
  const { promos } = usePromosPlacementEngine()

  const candidatePromos = promos.filter((promo) => promo.slotName === name)

  const promoComponents = candidatePromos.map((promo: PromoDataProps) => (
    <Renderer key={promo.promoId} promo={promo} />
  ))

  return <>{promoComponents}</>
}
