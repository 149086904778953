import type { PropsWithChildren } from "react"
import type { IconProps } from "../icons.types"

export const YouTubeIcon = ({ className }: PropsWithChildren<IconProps>) => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    role="img"
  >
    <path d="M12.338 4.201c1.405.007 6.77.06 8.257.459a2.756 2.756 0 0 1 1.945 1.945c.403 1.5.453 4.43.46 5.138v.314c-.007.709-.057 3.637-.46 5.138a2.756 2.756 0 0 1-1.945 1.945c-1.563.42-7.413.456-8.44.46h-.31a136.23 136.23 0 0 1-4.15-.079l-.524-.02c-1.567-.067-3.08-.177-3.766-.36a2.756 2.756 0 0 1-1.945-1.946c-.388-1.447-.449-4.222-.458-5.054v-.482c.01-.832.07-3.607.458-5.054A2.756 2.756 0 0 1 3.405 4.66c.686-.184 2.199-.294 3.766-.36l.524-.021c1.658-.06 3.282-.074 3.967-.078ZM10 8.6v6.6l5.515-3.2L10 8.6Z" />
  </svg>
)
