import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import useSWRImmutable from "swr/immutable"
import type { PromoDataProps } from "modules/promos/types"

/**
 * Promos will only be rendered in the client, so we can use relative URLs
 */
const PROMOS_URL = "/promos?regionCode=:regionCode"

/**
 * Hook to get promos data from the Rails promos API.
 */
export const usePromosData = (): PromoDataProps[] => {
  const regionCode = useRegionCode()

  const url = PROMOS_URL.replace(":regionCode", regionCode)
  const fetcher = (url: string) => fetch(url).then((r) => r.json())

  const { data: promos } = useSWRImmutable(url, fetcher)

  return promos || []
}
