/**
 * Utility function to retrieve client's local storage, if available.
 * Logic was extracted from Modernizr.
 *
 * @see https://github.com/Modernizr/Modernizr/blob/v3.11.2/feature-detects/storage/localstorage.js
 */
export function getLocalStorage(): Storage | undefined {
  const mod = "local-storage-availability-test"
  try {
    window.localStorage.setItem(mod, mod)
    window.localStorage.getItem(mod)
    window.localStorage.removeItem(mod)
    return window.localStorage
  } catch (e) {
    return undefined
  }
}
