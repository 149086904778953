import { usePromosPlacementEngine } from "modules/promos/hooks/usePromosPlacementEngine"
import { useEffect } from "react"
import { Creative } from "modules/promos/components/Slot/Creative"
import { useHasBeenInViewport } from "common/hooks/interaction/useHasBeenInViewport"
import type { PromoDataProps } from "modules/promos/types"
import { trackPromoEvent, PromoTrackingEvent } from "modules/promos/lib/tracking"
import { setUTMParams } from "common/lib/setUtmParams"

interface RendererProps {
  promo: PromoDataProps
}

/**
 * Component that will render a themed promo creative, attaching placement engine events to it.
 */
export const Renderer = ({ promo }: RendererProps) => {
  const {
    events: { onClose, onView, onClick },
  } = usePromosPlacementEngine()
  const { setRef: creativeRef, hasBeenInViewport } = useHasBeenInViewport(100)

  useEffect(() => {
    if (hasBeenInViewport) {
      trackPromoEvent(promo, PromoTrackingEvent.Impression)
      onView(promo)
    }
  }, [promo, onView, hasBeenInViewport])

  const events = {
    onClick: () => {
      trackPromoEvent(promo, PromoTrackingEvent.Engagement)
      onClick(promo)

      if (promo.data?.href) {
        const hrefWithUTMParams = setUTMParams(promo.data.href, {
          campaign: promo.campaign?.name,
          medium: promo.type,
          content: `promo-${promo.promoId}`,
        })

        window.location.assign(hrefWithUTMParams)
      }
    },
    onClose: () => {
      trackPromoEvent(promo, PromoTrackingEvent.Dismiss)
      onClose(promo)
    },
  }

  const creativeProps = { promo, events, creativeRef }

  return <Creative {...creativeProps} />
}
