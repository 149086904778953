/**
 * Promo theme mapping to Tailwind colors, using React context.
 * We can fetch a theme from this context by using the `usePromosTheme` hook.
 */

import { createContext, PropsWithChildren, ReactNode } from "react"

export enum PromoThemeName {
  Default = "default",
  Core = "core",
  Accent = "accent",
}

export interface ThemeProps {
  backgroundColor: string
  backgroundColorHover: string
}

const themesDictionary: Record<PromoThemeName, ThemeProps> = {
  [PromoThemeName.Core]: {
    backgroundColor: "bg-red-500",
    backgroundColorHover: "hover:bg-red-600",
  },
  [PromoThemeName.Default]: {
    backgroundColor: "bg-gray-600",
    backgroundColorHover: "hover:bg-gray-700",
  },
  [PromoThemeName.Accent]: {
    backgroundColor: "bg-indigo-600",
    backgroundColorHover: "hover:bg-indigo-700",
  },
}

const defaultPromosThemesContext = themesDictionary[PromoThemeName.Default]

export const PromosThemesContext = createContext<ThemeProps>(defaultPromosThemesContext)

interface PromosThemeComponentProps {
  name: PromoThemeName
  children: ReactNode
}

export const PromosTheme = ({ children, name }: PropsWithChildren<PromosThemeComponentProps>) => (
  <PromosThemesContext.Provider value={themesDictionary[name]}>
    {children}
  </PromosThemesContext.Provider>
)
