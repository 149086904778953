import type { PropsWithChildren } from "react"
import { MouseEvent } from "react"
import { isEnvEnabled, isRegionEnabled } from "common/components/Didomi"
import { linkClassNames } from "common/components/Footer"
import { useRegionConfig } from "modules/i18n/hooks/useRegionConfig"

export const isConsentPreferencesLink = (href: string) => href.includes("javascript:Didomi")

/**
 * Renders Didomi's consent preferences link.
 * It will render nothing if Didomi is disabled.
 */
export const ConsentPreferencesLink = ({
  title,
  children,
}: PropsWithChildren<{ title?: string }>) => {
  const regionConfiguration = useRegionConfig()

  if (!isRegionEnabled(regionConfiguration) || !isEnvEnabled()) {
    return null
  }

  const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    window.Didomi.preferences.show()
  }

  return (
    <a title={title} href="#" onClick={onClick} className={linkClassNames}>
      {children}
    </a>
  )
}
