import { SearchForm } from "./SearchForm"
import { DesktopMenu } from "./DesktopMenu"
import { DesktopTopbar } from "./DesktopTopbar"
import { MobileTopbar } from "./MobileTopbar"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { TcLogo } from "common/components/TcLogo"

export interface HeaderProps {
  noDesktopMenu?: boolean
}

export const Header = ({ noDesktopMenu }: HeaderProps) => {
  const t = useTranslations("layout.header")

  return (
    <header>
      <MobileTopbar />
      <DesktopTopbar />
      <div className="hidden border-t border-t-gray-100 lg:block">
        <section className="bg-gray-50 pt-5">
          <div className="tc-container container mx-auto">
            <header className="flex justify-between pb-7">
              <div>
                <TcLogo width={350} height={40} />
                <div className="mt-1 text-2xs text-gray-700">{t("slogan")}</div>
              </div>
              <SearchForm />
            </header>
            {noDesktopMenu ? null : <DesktopMenu />}
          </div>
        </section>
      </div>
    </header>
  )
}
