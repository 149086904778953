export enum PromoTypes {
  DonationDialog = "DonationDialog",
  StickyPopup = "StickyPopup",
}

export interface PromoDataProps {
  slotName: string
  promoId: number
  type: PromoTypes | string
  data: any
  campaign?: {
    name: string
  }
  category: string
}
