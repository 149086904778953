import { RegionCode } from "modules/i18n/config/region"
import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import svgLogo from "common/assets/logo.svg"
import svgLogoCaFr from "common/assets/logo-cafr.svg"

interface TcLogoProps {
  width: number
  height: number
}

export const TcLogo = ({ width, height }: TcLogoProps) => {
  const regionCode = useRegionCode()
  return regionCode === RegionCode.CaFr ? (
    <a href={`/${regionCode}`}>
      <img alt="La Conversation" src={svgLogoCaFr} width={width} height={height} />
    </a>
  ) : (
    <a href={`/${regionCode}`}>
      <img alt="The Conversation" src={svgLogo} width={width} height={height} />
    </a>
  )
}
