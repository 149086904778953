import type { PropsWithChildren } from "react"
import type { IconProps } from "../icons.types"

export const InstagramIcon = ({ className }: PropsWithChildren<IconProps>) => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    className={className}
  >
    <path d="M13.028 2.001c1.2.003 1.77.013 2.286.032l.193.007.616.028c1.065.048 1.791.217 2.428.465a4.902 4.902 0 0 1 1.77 1.153 4.902 4.902 0 0 1 1.154 1.771c.247.636.416 1.363.465 2.428.01.228.02.423.026.615l.007.192c.017.515.024 1.082.026 2.28v2.09c-.003 1.773-.018 2.16-.059 3.069-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 0 1-1.153 1.771 4.902 4.902 0 0 1-1.771 1.154c-.637.247-1.363.416-2.428.465-.952.043-1.326.057-3.328.06h-1.59c-2.002-.003-2.376-.017-3.328-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 0 1-1.772-1.154 4.902 4.902 0 0 1-1.153-1.77c-.247-.637-.416-1.364-.465-2.428l-.02-.437-.007-.202c-.019-.5-.027-.99-.031-1.998L2 12.822v-1.617l.002-.653c.003-.985.011-1.477.028-1.956l.008-.192.022-.52c.049-1.064.218-1.79.465-2.427a4.902 4.902 0 0 1 1.153-1.771A4.902 4.902 0 0 1 5.45 2.533c.636-.248 1.363-.417 2.427-.465.914-.042 1.295-.062 3.096-.067ZM12.7 3.832h-1.4c-1.503.003-2.884.015-3.34.036-.976.044-1.505.207-1.858.344C5.636 4.393 5.35 4.65 5 5c-.35.35-.614.643-.796 1.11-.136.352-.3.882-.344 1.857-.022.492-.034 2.055-.036 3.69v.702c.002 1.634.014 3.197.036 3.69.045.975.208 1.504.344 1.856.182.467.446.745.796 1.095.35.35.636.622 1.102.803.353.137.882.3 1.857.345.492.022 2.055.034 3.69.036h.702c1.635-.002 3.198-.014 3.69-.036.975-.045 1.504-.208 1.857-.345.467-.181.752-.453 1.102-.803.35-.35.614-.628.796-1.095.137-.352.3-.881.344-1.856.02-.457.033-1.838.036-3.341v-1.4c-.003-1.504-.015-2.884-.036-3.341-.044-.975-.207-1.505-.344-1.857-.182-.467-.446-.76-.796-1.11-.35-.35-.635-.607-1.102-.788-.353-.137-.882-.3-1.857-.344-.457-.021-1.837-.033-3.341-.036Zm-.7 3.04a5.135 5.135 0 1 1 0 10.27 5.135 5.135 0 0 1 0-10.27Zm0 1.802a3.333 3.333 0 1 0 0 6.667 3.333 3.333 0 0 0 0-6.667Zm5.338-3.204a1.2 1.2 0 1 1 0 2.4 1.2 1.2 0 0 1 0-2.4Z" />
  </svg>
)
