import { ChevronDownIcon, PencilSquareIcon } from "@heroicons/react/20/solid"
import { Button } from "../Button"
import { Dropdown } from "../Dropdown"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { useViewer } from "common/hooks/data/useViewer"
import { usePageProps } from "common/hooks/data/usePageProps"
import { useRouter } from "common/lib/router"
import type { AppProps } from "common/lib/getAppServerSideProps"

const ANALYTICS_URL = "https://analytics.theconversation.com"

/**
 * Component responsible to render account menu actions, such as Button to the viewer profile, sign out Button, etc.
 * In case the viewer is not logged in, it will render a "sign in" button instead.
 */
export const AccountMenu = () => {
  const { viewer, isEditor } = useViewer()
  const { editUrl } = usePageProps<AppProps>()
  const router = useRouter()
  const t = useTranslations("layout.header.user_navbar")

  const user = viewer?.user
  const analytics = viewer?.user?.allow.analytics
  const moderateComments = viewer?.user?.allow.moderateComments
  const commentNotificationCount = viewer?.user?.commentNotificationCount
  const newsFeedCount = viewer?.user?.newsFeedCount
  const shouldShowAdminDivider = isEditor && editUrl

  if (!user) {
    return (
      <>
        <div className="hidden lg:block">
          <Button href={`/sign_in?return_to=${router.asPath}`} withDivider>
            {t("sign_in")}
          </Button>
        </div>
        <div className="lg:hidden">
          <Button href={`/sign_in?return_to=${router.asPath}`} type="bold">
            {t("sign_in")}
          </Button>
        </div>
      </>
    )
  }

  const { avatar, name, id } = user

  const dropdownOverlay = (
    <>
      {analytics && (
        <Button href={ANALYTICS_URL} role="menuitem">
          {t("analytics")}
        </Button>
      )}
      {moderateComments && (
        <Button href="/editorial/comments/editor" role="menuitem">
          {t("moderate_comments")}
          {!!commentNotificationCount && (
            <span className="ml-3 inline-block size-4 rounded-full bg-yellow-500"></span>
          )}
        </Button>
      )}
      <Button href={`/profiles/${id}/news`} role="menuitem">
        {t("profile")}
      </Button>
      <Button href="/sign_out" role="menuitem">
        {t("sign_out")}
      </Button>
      {shouldShowAdminDivider && (
        <>
          <div className="my-1 border-b border-gray-100" role="presentation" />
          <p className="mb-1 mt-3 px-2.5 text-2xs font-bold">{t("admin")}</p>
        </>
      )}
      {isEditor && editUrl && (
        <Button href={editUrl}>
          <PencilSquareIcon className="w-5 fill-gray-600 pr-1" />
          {t("edit_page")}
        </Button>
      )}
    </>
  )

  return (
    <Dropdown overlay={dropdownOverlay} placement="right" wideOverlay>
      <Button withDivider type="icon">
        <div className="mr-1 hidden text-xs md:block">{name}</div>
        <ChevronDownIcon className="mr-1 w-4" />
        <div className="relative">
          <img src={avatar} alt={name} className="w-9 rounded-full" />
          {!!newsFeedCount && (
            <div className="absolute -right-2 -top-0.5 rounded-full bg-red-500 px-1.5 py-0.5 text-3xs font-bold text-white ring-2 ring-white">
              {newsFeedCount}
            </div>
          )}
        </div>
      </Button>
    </Dropdown>
  )
}
