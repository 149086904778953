import { PropsWithChildren, StrictMode } from "react"
import { Header } from "common/components/Header"
import { Footer } from "common/components/Footer"
import { PromosContext } from "modules/promos/contexts/PromosContext"
import { Slot } from "modules/promos/components/Slot/Slot"
import { useViewer } from "common/hooks/data/useViewer"
import { useRouter } from "common/lib/router"
import { NewHeader } from "common/components/NewHeader"

interface ContentProps {
  as?: "main" | "div"
}

export const Content = ({ children, as: Tag = "main" }: PropsWithChildren<ContentProps>) => {
  return <Tag className="tc-container container mx-auto my-5">{children}</Tag>
}

export const Layout = ({ children }: PropsWithChildren<{}>) => {
  const currentYear = new Date().getFullYear()
  const { viewer } = useViewer()
  const router = useRouter()
  // To see the new header, a user must include a truthy value for the `newHeader` param in the URL.
  // In prod, they will also need to be logged in and have the `page_builder_new_header` feature
  // flip enabled.
  const shouldDisplayNewHeader =
    (process.env.NODE_ENV !== "production" || viewer?.user?.allow.newHeader) &&
    router?.query?.newHeader

  return (
    <StrictMode>
      <PromosContext>
        <div className="flex h-screen flex-col justify-between">
          {shouldDisplayNewHeader ? <NewHeader /> : <Header />}

          <Slot name="banner" />

          <Content>{children}</Content>
          <Footer year={currentYear} />
        </div>
        <div className="relative z-10">
          <Slot name="modal" />
        </div>
      </PromosContext>
    </StrictMode>
  )
}
