import type { PropsWithChildren } from "react"
import type { IconProps } from "../icons.types"

export const TikTokIcon = ({ className }: PropsWithChildren<IconProps>) => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    role="img"
  >
    <path d="M3.7 18.77a6.845 6.845 0 0 1-.658-3.765c.265-2.389 1.487-4.345 3.386-5.422 1.002-.567 2.436-.85 3.504-.689l.618.093v3.82l-.584-.024c-1.408-.059-2.457.551-2.999 1.743-.259.57-.325 1.582-.142 2.2.224.76.903 1.4 1.692 1.75.8.354 1.718.18 2.267-.095.638-.321 1.111-.754 1.386-1.32.18-.368.237-.611.292-1.239.039-.43.071-3.94.072-7.801L12.536 1h3.497c.025 1.34.428 2.36.83 3.017.101.164.452.566.78.893.635.634 1.888 1.475 3.357 1.545a90.06 90.06 0 0 0 0 1.772V10c-1.003-.005-3.178-.612-4.967-1.732-.004.764.005 2.056-.029 4.391-.059 4.048-.07 4.274-.225 4.84-.596 2.177-1.935 3.794-3.699 4.465-1.078.411-2.458.523-3.685.298-1.984-.362-3.86-1.758-4.694-3.491Z" />
  </svg>
)
